.tile_class {
    padding: 0.5em 1em;
    background-color: var(--primary);
    width: fit-content;
    font-size: 3em;
    color: white;
    font-family: 'HelveticaNeue';
    font-weight: bold;
    border-radius: 10px;
}
.tile_container {
    width: fit-content;
    text-align: center;
    max-width: 175px;
    margin-right: 2em;
}
.tile_container p {
    font-family: 'HelveticaNeue';
    margin-top: 0.75em;
    font-weight: 500;
}