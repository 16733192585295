.project_container {
    border-top: 3px solid var(--primary);
    border-left: 3px solid var(--primary);
    border-bottom: 3px solid var(--primary);
    padding: 1em;
    margin-bottom: 1em;
}
.member_container {
    border-top: 3px solid var(--primary);
    border-right: 3px solid var(--primary);
    padding: 1em;
}