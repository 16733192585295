.sb-header {
    text-align: start;
}
.sb-header-back-btn {
    font-size: 3em;
    margin-bottom: -0.4rem;
}
.sb-header-back-btn:hover {
    color: var(--primary);
    transition: all 200ms ease;
}