.sidenav-container {
  background-color: var(--secondary);
  height: 100vh;
  text-align: center;
  position: sticky;
  top: 0;
}

.circleBg {
  height: 100px;
  width: 100px;
  background-color: var(--secondary);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 80%;
}

.divider {
  color: var(--lightGrey);
}

.userProfile {
  color: var(--lightGrey);
  position: absolute;
  bottom: 0;
  display: flex;
  text-align: center;
}

.text {
  line-height: 2;
  margin-left: 10px;
}

.icons {
  bottom: 0;
  padding-bottom: 0 !important;
}

.Sb-divider {
  height: 1px !important;
  width: 85%;
  color: var(--lightGrey);
  display: inline-block;
  margin-top: 30px;
  margin-bottom: 0px;
}
.btm {
  position: absolute;
  bottom: 70px;
  left: 0;
  margin: 0 17px;
}
.setting-icon:hover {
  transform: rotate(45deg) scale(1.1);
}